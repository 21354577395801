<template>
  <div class="index">
    <web-header active-url="/"></web-header>
    <div class="index-one" v-if="carouselList && carouselList.length > 0">
      <swiper :options="oneSwiperOption" class="index-one-swiper" ref="oneSwiper">
        <swiper-slide v-for="(item,index) in carouselList" :key="index">
          <img :src="item.image.img" @click="slideJump(item)" style="width: 100%" />
        </swiper-slide>
        <div class="swiper-pagination index-pagination" slot="pagination"></div>
        <div class="swiper-button-prev index-swiper-prev" slot="button-prev"></div>
        <div class="swiper-button-next index-swiper-next" slot="button-next"></div>
      </swiper>
    </div>
    <div class="index-two" v-if="collegeNews && collegeNews.length > 0">
      <div class="f-wrap">
        <div class="f-wrap-header">
          <div class="f-wrap-header-title">
            <div class="f-wrap-header-title-en">NEWS</div>
            <div class="f-wrap-header-title-cn">研究院动态</div>
          </div>
          <a href="/trends" target="_blank">View More</a>
        </div>
        <div class="index-wrap-content">
          <div class="index-two-left">
            <swiper :options="twoSwiperOptionLeft" class="index-two-left-swiper" ref="twoLeftSwiper">
              <swiper-slide v-for="(item,index) in collegeNews" :key="index">
                <a :href="'/news_detail/'+item.class_id+'/'+item.id" target="_blank">
                  <img :src="item.litpicindex.img" />
                </a>
              </swiper-slide>
            </swiper>
          </div>
          <div class="index-two-right">
            <swiper :options="twoSwiperOptionRight" class="index-two-right-swiper" ref="twoRightSwiper">
              <swiper-slide v-for="(item,index) in collegeNews" :date-id="index" :key="index">
                <div class="tit">
                  <a :href="'/news_detail/'+item.class_id+'/'+item.id" target="_blank" :title="item.title">{{ item.title }}</a>
                </div>
                <div class="date">{{ dateFormat(item.send_time, 'YYYY-MM-DD') }}</div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <div class="index-three" v-if="studyFruit && studyFruit.length > 0">
      <div class="f-wrap">
        <div class="f-wrap-header">
          <div class="f-wrap-header-title">
            <div class="f-wrap-header-title-en">RESEARCH</div>
            <div class="f-wrap-header-title-cn">研究成果</div>
          </div>
          <a href="/achievements" target="_blank">View More</a>
        </div>
        <div class="index-three-content">
          <div class="index-three-content-item" v-for="(item, index) in studyFruit" :key="index">
            <div class="date">{{ dateFormat(item.send_time, 'YYYY-MM-DD') }}</div>
            <div class="tit">
              <a :href="'/news_detail/'+item.class_id+'/'+item.id" target="_blank" :title="item.title">{{ item.title }}</a>
            </div>
            <div class="desc">{{ item.introduce }}</div>
            <div class="more">
              <a :href="'/news_detail/'+item.class_id+'/'+item.id" target="_blank"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-four" v-if="academicList && academicList.length > 0">
      <div class="f-wrap">
        <div class="f-wrap-header">
          <div class="f-wrap-header-title">
            <div class="f-wrap-header-title-en">EXPERTS</div>
            <div class="f-wrap-header-title-cn">学术交流</div>
          </div>
          <a href="/exchanges" target="_blank">View More</a>
        </div>
        <div class="index-four-content">
          <swiper :options="fourSwiperOption" class="index-four-content-swiper" ref="fourSwiper">
            <swiper-slide v-for="(item,index) in academicList" :key="index">
              <div class="swiper-slide-item" v-for="(jtem, jndex) in item.list" :key="jndex">
                <a target="_blank" :href="'/news_detail/'+jtem.class_id+'/'+jtem.id" tabindex="0">
                  <div class="img" v-if="jtem.litpicindex && jtem.litpicindex.img">
                    <img :src="jtem.litpicindex.img" alt="" title="">
                  </div>
                  <div class="img" v-else>
                    <img :src="require('@/assets/default.jpg')" alt="" title="">
                  </div>
                  <div class="name">{{ jtem.author }}</div>
                  <div class="subheading">{{ jtem.position }}</div>
                  <div class="desc">{{ jtem.title }}</div>
                </a>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-opera">
            <div class="swiper-button-prev" @click="fourSlidePrev"></div>
            <div class="swiper-button-next" @click="fourSlideNext"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-five">
      <div class="f-wrap">
        <div class="f-wrap-header">
          <div class="f-wrap-header-title">
            <div class="f-wrap-header-title-en">PUBLICATIONS</div>
            <div class="f-wrap-header-title-cn">出版物</div>
          </div>
          <div class="index-five-tab" v-if="publicationClass && publicationClass.length > 1">
            <div :class="{'index-five-tab-item': true, 'current': currentClass === item.id }" v-for="(item, index) in publicationClass" :key="index" @click="publicationClassSelect(item)">
              <div class="font20">{{ item.class_name }}</div>
            </div>
          </div>
          <a href="/books" target="_blank">View More</a>
        </div>
        <div class="index-five-content">
          <swiper :options="fiveSwiperOption" class="index-five-swiper">
            <swiper-slide v-for="(item,index) in publications" :key="index">
              <div class="slide-item">
                <a :href="'/book_detail/'+item.class_id+'/'+item.id" target="_blank">
                  <div class="img">
                    <img :src="item.litpicindex.img" />
                  </div>
                  <div class="title font18">
                    {{ item.title }}
                  </div>
                </a>
              </div>
            </swiper-slide>
            <div class="swiper-button-next index-five-next" slot="button-prev"></div>
            <div class="swiper-button-prev index-five-prev" slot="button-next"></div>
          </swiper>
        </div>
      </div>
    </div>
    <web-footer></web-footer>
    <web-right-menu class="right-menu"></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import SwiperMixin from "./swiperMixin";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { slideList } from "@/api/carousel";
import { articleList, articleSection } from "@/api";
export default {
  name: "Index",
  mixins: [SwiperMixin],
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      // 轮播图数据
      carouselList: [],
      // 研究院动态
      collegeNews: [],
      // 研究成果
      studyFruit: [],
      // 学术交流
      academicList: [],
      // 出版物
      publications: [],
      // 出版物分类
      publicationClass: [],
      // 默认选中的出版物分类
      currentClass: 0,
    }
  },
  created() {
    this.resetAll();
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.oneSwiper.initSwiper();
      if (this.$refs.twoRightSwiper && this.$refs.twoLeftSwiper) {
        this.twoSwiperOptionLeft.thumbs.swiper = this.$refs.twoRightSwiper.$swiper;
        this.$refs.twoRightSwiper.initSwiper();
        this.$refs.twoLeftSwiper.initSwiper();
        window.parentSlide = this.$refs.twoLeftSwiper.$swiper;
      }
    });
  },
  methods: {
    // 重新载入所有数据
    async resetAll () {
      this.getSlideList();
      // 获取研究动态
      this.collegeNews = await this.getArticleList(16, 4, "litpicindex_id:0", "litpicindex_id:>","topping", "desc");
      // 获取研究成果
      this.studyFruit = await this.getArticleList(29, 4, "topping:1", "topping:=", "send_time", "desc");
      // 获取学术交流
      this.getAcademicList();
      // 获取出版物分类
      this.getPublicationClass();
      // 设置标题
      this.setPageSeo(this.$store.getters.getWebSiteTitle, this.$store.getters.getWebSiteKeywords, this.$store.getters.getWebSiteDesprition);
    },
    // 出版物分类选择
    async publicationClassSelect(row) {
      this.publications = [];
      this.currentClass = row.id;
      this.publications = await this.getArticleList(row.id, 12, "topping:1|litpicindex_id:0", "topping:=|litpicindex_id:>","sort", "desc");
    },
    // 获取轮播图
    async getSlideList() {
      let res = await slideList({ orderBy: "sort", sortedBy: "asc"});
      if (res && res.code == 200) {
        this.carouselList = res.data.list;
        if (this.carouselList.length > 1) {
          this.oneSwiperOption.loop = true;
        } else {
          this.oneSwiperOption.loop = false;
        }
      }
    },
    // 轮播图跳转
    slideJump(row) {
      if (row.link) {
        this.routerLink(row.link, row.target);
      }
    },
    // 日期格式化
    dateFormat(e, format) {
      return this.$moment(e).format(format);
    },
    // 获取文章列表
    async getArticleList(categoryId, size, search, searchFields, order, sort) {
      let param = {
        pageSize: size,
        currentPage: 1,
        orderBy: order,
        sortedBy: sort,
        search: search,
        searchFields: searchFields
      };
      let arr = [];
      let res = await articleList(param, categoryId);
      if (res && res.code == 200) {
        arr = res.data.list;
      }
      return arr;
    },
    // 获取学术交流
    async getAcademicList() {
      // 获取学术交流
      let arr = await this.getArticleList(42, 12, "topping:1", "topping:=", "sort", "desc");
      let slideOneInfo = { list: [] };
      let slideTwoInfo = { list: [] };
      if (arr && arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          if (i < 6) {
            slideOneInfo.list.push(arr[i]);
          } else {
            slideTwoInfo.list.push(arr[i]);
          }
        }
        this.academicList = [slideOneInfo, slideTwoInfo];
      }
    },
    // 获取出版物分类
    async getPublicationClass() {
      let param = {
        pageSize: 5,
        currentPage: 1,
        orderBy: "sort",
        sortedBy: "desc",
        search: "parent_id:7",
        searchFields: "parent_id:="
      };
      let res = await articleSection(param);
      if (res && res.code == 200) {
        this.publicationClass = res.data.list;
        if (res.data && res.data.list && res.data.list.length > 0) {
          this.currentClass = res.data.list[0]['id'];
          this.publications = await this.getArticleList(this.currentClass, 12, "topping:1|litpicindex_id:0", "topping:=|litpicindex_id:>","sort", "desc");
        } else {
          this.publications = await this.getArticleList(17, 12, "topping:1|litpicindex_id:0", "topping:=|litpicindex_id:>","sort", "desc");
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.index-one {
  cursor: pointer;
  &-swiper {
    width: 100%;
    .index-pagination {
      bottom: 50px !important;
    }
  }
}
.index-two {
  padding: 117px 0;
  .index-wrap {
    &-content {
      background: #004898;
      display: flex;
      .index-two-left {
        width: 931px;
        height: 100%;
        overflow: hidden;
        &-swiper {
          width: 100%;
          height: 523px;
        }
      }
      .index-two-right {
        &-swiper {
          width: 299px;
          .swiper-slide {
            padding: 20px 40px;
            width: calc(100% - 80px);
            color: #fff;
            height: 90.75px !important;
            border-top: 1px solid #3584c9;
            margin-bottom: 0 !important;
            &:first-child {
              border: none;
            }
            .tit {
              position: relative;
              margin-bottom: 27px;
              font-size: 16px;
              a {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;  /* 可选，当超过两行时显示省略号 */
              }
              &::before {
                content: '';
                position: absolute;
                right: 102%;
                top: 6px;
                margin-right: 5px;
                width: 10px;
                height: 10px;
                border: 2px solid #fff;
                border-radius: 999px;
              }
            }
            .date {
              font-family: avian;
              font-size: 16px;
            }
            &.swiper-slide-thumb-active {
              background: #0084cf;
              .tit {
                font-weight: bold;
                &::before {
                  background: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
}
.index-three {
  padding-bottom: 102px;
  &-content {
    display: flex;
    &::after {
      content: '';
      display: table;
      clear: both;
    }
    &-item {
      width: 285px;
      height: 323px;
      padding: 0 15px;
      position: relative;
      overflow: hidden;
      .date {
        padding-top: 10px;
        font-size: 14px;
      }
      .tit {
        position: relative;
        margin: 19px 0 15px;
        font-size: 18px;
        height: 55px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;  /* 可选，当超过两行时显示省略号 */
      }
      .desc {
        color: #707070;
        line-height: 24px;
        text-align: justify;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;  /* 可选，当超过两行时显示省略号 */
      }
      .more {
        width: 40px;
        height: 40px;
        background: url(../../assets/more_one.png) no-repeat center;
        background-size: cover;
        position: absolute;
        bottom: 16px;
        left: 15px;
        a {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      &:hover {
        background: url(../../assets/index_three_hover.jpg) no-repeat center;
        .tit {
          color: #004898;
          font-weight: bold;
        }
        .more {
          background-image: url(../../assets/more_one_bg.png);
        }
      }
    }
  }
}
.index-four {
  position: relative;
  padding-bottom: 67px;
  background: url(../../assets/index-four-bg.jpg) bottom center no-repeat;
  &-content {
    display: block;
    user-select: none;
    &-swiper {
      width: 100%;
      position: static;
      .swiper-slide {
          display: flex;
          flex-wrap: wrap;
          &-item {
            padding: 50px 14px 0;
            width: 286px;
            margin-left: 77px;
            height: 288px;
            text-align: center;
            &:first-child {
              margin-left: 133px;
            }
            &:nth-child(4) {
              padding-top: 2px;
              margin-left: 25px;
            }
            &:nth-child(5) {
              padding-top: 2px;
            }
            &:nth-child(6) {
              padding-top: 2px;
            }
            .img {
              margin: auto;
              margin-bottom: 10px;
              width: 94px;
              height: 94px;
              border-radius: 50%;
              overflow: hidden;
              img {
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 999px;
                transition: transform 0.3s ease;
              }
            }
            .name {
              font-size: 26px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .subheading {
              margin-bottom: 5px;
              color: #707070;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .desc {
              font-size: 18px;
              color: #004898;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
          & a:hover {
            img {
              transform: scale(1.1);
            }
          }
      }
    }
    .swiper-opera {
      position: absolute;
      width: 112px;
      height: 40px;
      top: 0;
      right: 11%;
      z-index: 99;
      .swiper-button-prev,.swiper-button-next {
        width: 40px;
        height: 40px;
        border: 1px solid #004898;
        border-radius: 50%;
        color: #004898;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:after {
          font-size: 20px !important;
        }
        &:hover {
          color: #ffffff;
          background-color: #004898;
        }
      }
      .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
.index-five {
  height: 694px;
  background: url("../../assets/index_five_bg.jpg") center no-repeat;
  .f-wrap {
    padding-top: 110px;
  }
  .f-wrap-header {
    border: none;
    margin-bottom: 20px;
    &-title {
      &-en {
        color: #81a5e0;
        opacity: 0.2;
      }
    }
    &:before {
      width: 0;
      height: 0;
    }
  }
  &-tab {
    display: flex;
    &-item {
      position: relative;
      display: inline-block;
      padding: 6px 0;
      border-bottom: 2px solid transparent;
      vertical-align: top;
      cursor: pointer;
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
      &.current {
        border-color: #004898;
        color: #004898;
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          border: 6px solid transparent;
          border-bottom: 5px solid #004898;
          display: block;
          transform: translateX(-50%);
        }
      }
    }
  }
  &-content {
    padding: 0 60px;
    .index-five-swiper {
      position: static;
      .slide-item {
        margin: 0 8px;
        height: 395px;
        border: 2px solid #abc5ee;
        text-align: center;
        &:hover {
          border-color: #004898;
        }
        img {
          width: 100%;
          height: 320px;
          overflow: hidden;
        }
        .title {
          padding: 0 30px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow-wrap: break-word;
        }
      }
      .index-five-prev, .index-five-next {
        width: 40px;
        height: 40px;
        border: 1px solid #004898;
        border-radius: 50%;
        &:after {
          font-size: 24px;
          color: #004898;
        }
        &.swiper-button-disabled {
          opacity: 1 !important;
          cursor: pointer !important;
          pointer-events: auto !important;
        }
        &:hover {
          color: #ffffff;
          background: #004898;
          border-color: #004898;
          &:after {
            color: #ffffff;
          }
        }
      }
      .index-five-prev {
        left: 0;
      }
      .index-five-next {
        right: 0;
      }
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .index-one {
    &-swiper {
      .index-pagination {
        bottom: 25px !important;
      }
    }
  }
  .index-two {
    padding: 50px 0;
    .index-wrap {
      &-content {
        display: block;
        .index-two-left {
          width: 100%;
          &-swiper {
            height: auto;
            .swiper-slide {
              height: auto !important;
              &.swiper-slide-active {
                opacity: 1 !important;
              }
            }
          }
        }
        .index-two-right {
          &-swiper {
            width: 100%;
            .swiper-slide {
              height: auto;
              padding: 30px 50px 80px 80px;
              width: calc(100% - 130px);
              .tit {
                font-size: 26px;
                font-weight: bold;
                &:before {
                  width: 20px;
                  height: 20px;
                }
              }
              .date {
                font-size: 26px;
              }
            }
          }
        }
      }
    }
  }
  .index-three {
    padding-bottom: 60px;
    &-content {
      flex-direction: column;
      &-item {
        width: calc(100% - 30px);
        height: auto;
        padding-bottom: 30px;
        .date {
          font-size: 18px;
        }
        .tit {
          font-size: 28px;
          height: auto;
        }
        .desc {
          font-size: 22px;
          line-height: 36px;
        }
        .more {
          left: auto;
          bottom: 0;
          right: 15px;
        }
      }
    }
  }
  .index-four {
    background: none;
    background-position: 0 0;
    margin-bottom: 60px;
    &-content {
      &-swiper {
        overflow: inherit;
        .swiper-slide {
          &-item {
            width: calc(50% - 40px);
            padding: 50px 20px;
            margin: 60px 0 0 0;
            &:first-child, &:nth-child(2) {
              margin: 0;
              padding: 50px 20px;
            }
            &:nth-child(4), &:nth-child(5), &:nth-child(6) {
              margin: 60px 0 0 0;
              padding: 50px 20px;
            }
            .img {
              width: 200px;
              height: 200px;
            }
            .name {
              font-size: 38px;
            }
            .subheading {
              margin-bottom: 10px;
              font-size: 26px;
            }
            .desc {
              font-size: 28px;
            }
          }
        }
      }
      .swiper-opera {
        right: 32%;
        top: 15px;
      }
    }
  }
  .index-five {
    height: auto;
    .f-wrap {
      padding-top: 200px;
    }
    &-tab {
      position: absolute;
      top: 70px;
    }
    &-content {
      width: calc(100% - 140px);
      margin: auto;
      .index-five-swiper {
        padding-top: 100px;
        padding-bottom: 100px;
        .slide-item {
          margin: 0 5px;
          padding: 15px;
          img {
            height: 320px;
          }
          .title {
            padding: 0 5px;
          }
        }
        .index-five-prev, .index-five-next {
          top: 60%;
        }
        .index-five-prev {
          left: 30px;
        }
        .index-five-next {
          right: 30px;
        }
      }
    }
  }
}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .index-one {
    &-swiper {
      .index-pagination {
        bottom: 10px !important;
      }
    }
  }
  .index-two {
    padding: 30px 0;
    .index-wrap {
      &-content {
        display: block;
        .index-two-left {
          width: 100%;
          &-swiper {
            height: auto;
            .swiper-slide {
              height: auto !important;
              &.swiper-slide-active {
                opacity: 1 !important;
              }
            }
          }
        }
        .index-two-right {
          &-swiper {
            width: 100%;
            .swiper-slide {
              height: auto;
              padding: 20px 20px 20px 40px;
              width: calc(100% - 60px);
              .tit {
                font-size: 15px;
              }
              .date {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
  .index-three {
    padding-bottom: 30px;
    &-content {
      flex-direction: column;
      &-item {
        width: calc(100% - 30px);
        height: auto;
        padding-bottom: 30px;
        .date {
          font-size: 14px;
        }
        .tit {
          font-size: 18px;
          height: auto;
        }
        .desc {
          font-size: 16px;
          line-height: 26px;
        }
        .more {
          width: 30px;
          height: 30px;
          left: auto;
          bottom: 0;
          right: 15px;
        }
      }
    }
  }
  .index-four {
    background: none;
    background-position: 0 0;
    margin-bottom: 0px;
    &-content {
      &-swiper {
        overflow: inherit;
        .swiper-slide {
          &-item {
            width: calc(50% - 20px);
            padding: 0px 10px;
            margin: 0px 0 0 0;
            &:first-child, &:nth-child(2) {
              margin: 0;
              padding: 30px 10px;
            }
            &:nth-child(4), &:nth-child(5), &:nth-child(6) {
              margin: 0px 0 0 0;
              padding: 0px 10px;
            }
            .img {
              width: 104px;
              height: 104px;
            }
            .name {
              font-size: 22px;
            }
            .subheading {
              margin-bottom: 10px;
              font-size: 14px;
            }
            .desc {
              font-size: 16px;
            }
          }
        }
      }
      .swiper-opera {
        width: 76px;
        height: 22px;
        right: 32%;
        top: 20px;
        .swiper-button-prev,.swiper-button-next {
          width: 22px;
          height: 22px;
          &:after {
            font-size: 14px !important;
          }
        }
      }
    }
  }
  .index-five {
    height: auto;
    .f-wrap {
      padding-top: 110px;
    }
    &-tab {
      position: absolute;
      top: 40px;
    }
    &-content {
      width: calc(100% - 100px);
      margin: auto;
      .index-five-swiper {
        padding-top: 60px;
        padding-bottom: 60px;
        .slide-item {
          margin: 0 5px;
          height: 180px;
          img {
            width: 100%;
            height: 120px;
          }
          .title {
            padding: 0 5px;
          }
        }
        .index-five-prev, .index-five-next {
          width: 26px;
          height: 26px;
          &:after {
            font-size: 18px;
            color: #004898;
          }
        }
        .index-five-prev, .index-five-next {
          top: 65%;
        }
        .index-five-prev {
          left: 15px;
        }
        .index-five-next {
          right: 15px;
        }
      }
    }
  }
}
</style>
